<template>

<div class="bg-default">
    <header class="header">
        <div class="header-left">
            <a href="#">
                <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings">
            </a>
        </div>
        <div class="header-center">
        </div>
        <div class="header-right">
            <a @click="exit()">
                <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit">
            </a>
        </div>
    </header>
    <main class="main">
        <div class="container">
            <div class="card card-800 card-centerred">
                <div class="card-icon card-icon-circle mb-48">
                    <img src="img/hourglass-icon.png" alt="Hourglass icon">
                </div>
                <div class="card-title mb-64">
                    Hamarosan indul<br>
                    a kísérlet!
                </div>
                <div class="card-info">
                    A kísérlet akkor indulhat, ha a csoportból mindenki készen áll.
                </div>
            </div>
        </div>
    </main>
    <footer class="footer">
    </footer>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "GroupWaiting",
    computed: {
        ...mapGetters(["status","actualGameTurnId","stopGameTurnId"])
    },
    methods: {
        goNext() {
             this.$router.push('waitingcounter2')
        }
    },
    watch: {
      stopGameTurnId(newTurn,oldTurn) {
          if(newTurn !== this.actualGameTurnId) this.$router.push('waitingcounter2');
  },
    },
    mounted() {
    }
}
</script>

<style>

</style>
